import React from "react";

import { logSearchEvent } from "utilities/firebase";
import { useLocation, useNavigate } from "react-router-dom";

import "./SearchBar.css";

const useLocationState = (): [any, (value: string) => void] => {
  const location = useLocation();
  const navigate = useNavigate();

  return [location.state, (value: string) => navigate(".", { state: value })];
};

type SearchBarProps = {
  onSearch: (value: string) => void;
};

const SearchBar = (props: SearchBarProps) => {
  const [locationState, setLocationState] = useLocationState();
  const [value, setValue] = React.useState<string>(locationState ?? "");

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    logSearchEvent({ search_term: value, persona: "watch-web" });
    setLocationState(value);
    props.onSearch(value);
  };

  const handleChange: React.FormEventHandler = (event) => {
    const currentValue = (event.currentTarget as HTMLInputElement).value;
    setValue(currentValue);
  };

  // Load locationState on mount
  React.useEffect(() => {
    if (locationState) {
      props.onSearch(locationState);
    }
  }, [locationState, props]);

  return (
    <div className="SearchBar">
      <form className="SearchBar-Form" noValidate onSubmit={handleSubmit}>
        <input
          className="SearchBar-Input"
          id="search"
          type="search"
          name="search"
          autoComplete="off"
          value={value}
          onChange={handleChange}
        />
      </form>
    </div>
  );
};

export default SearchBar;
