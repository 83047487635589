import React from "react";

import Loader from "components/Loader";
import useLayout from "hooks/useLayout";
import { Element, render } from "utilities/factory";

import "./Cards.css";

type CardsProps = {
  app: string;
  persona: string;
  screen: string;
  release: string;
  query?: Record<string, string>;
};

const Cards: React.FC<CardsProps> = (props) => {
  const layout = useLayout({
    app: props.app,
    persona: props.persona,
    screen: props.screen,
    release: props.release,
    query: props.query,
  });

  return (
    <div>
      {layout.isLoading ? (
        <div className="Cards-Loading">
          <Loader />
        </div>
      ) : layout.isError ? (
        <div className="Cards-Error">
          Layout Error: {(layout.error as Error).message}
        </div>
      ) : (
        <div>{render(layout.data?.element as Element)}</div>
      )}
    </div>
  );
};

export default Cards;
