import React from "react";
import CastProvider, { useMedia, useCast } from "react-chromecast";

import { resolveUrl } from "utilities/frMediaService";

import "./Chromecast.css";

const Play = (props: { url: string; className: string }) => {
  const media = useMedia();
  const playVideo = React.useCallback(async () => {
    if (media) {
      await media.playMedia(await resolveUrl(props.url));
    }
  }, [media, props.url]);

  return (
    <button className={props.className} onClick={playVideo}>
      Play
    </button>
  );
};

const Connect = (props: { className: string }) => {
  const cast = useCast({
    initialize_media_player: "DEFAULT_MEDIA_RECEIVER_APP_ID",
    auto_initialize: true,
  });

  const handleClick = React.useCallback(async () => {
    if (cast.castReceiver) {
      await cast.handleConnection();
    }
  }, [cast]);

  return (
    <button className={props.className} onClick={handleClick}>
      Connect
    </button>
  );
};

type ChromecastType = {
  url: string;
  children: React.ReactNode;
};

const Chromecast = (props: ChromecastType) => (
  <div className="Chromecast">
    <CastProvider>
      {props.children}
      <div className="Chromecast-Buttons">
        <img
          className="Chromecast-Icon"
          src="https://icon-library.com/images/chromecast-icon-png/chromecast-icon-png-18.jpg"
          alt="chromecast"
        />
        <Connect className="Chromecast-Button" />
        <Play className="Chromecast-Button" url={props.url} />
      </div>
    </CastProvider>
  </div>
);

export default Chromecast;
