import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { Link } from "react-router-dom";

import useConfiguration from "hooks/useConfiguration";
import useTranslation from "hooks/useTranslation";
import useAuthentication from "hooks/useAuthentication";
import { logFrClickEvent } from "utilities/firebase";
import WacNav from "components/WacNav";

import "./Navbar.css";

const NavigationBar = (props: any) => {
  const configuration = useConfiguration();
  const translation = useTranslation();
  const authentication = useAuthentication();

  return (
    <div className="Navbar">
      <Navbar expand="lg" variant="dark">
        <Navbar.Brand
          className="me-4"
          as={Link}
          to={`/${process.env.REACT_APP_CHL_PERSONA}`}
          onClick={() =>
            logFrClickEvent({
              name: "home",
              persona: "watch-web",
            })
          }
        >
          <img className="Navbar-Logo" alt="" src={configuration.logoUrl} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link
              className="me-4"
              as={Link}
              to={`/${process.env.REACT_APP_CHL_PERSONA}`}
              onClick={() =>
                logFrClickEvent({
                  name: "home",
                  persona: "watch-web",
                })
              }
            >
              <i className="bi bi-house-door-fill me-2" />
              {translation.t("home")}
            </Nav.Link>
            <NavDropdown
              className="me-4"
              title={
                <span>
                  <i className="bi bi-star-fill me-2"></i>
                  {translation.t("leagues")}
                </span>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                as={Link}
                to={`/${process.env.REACT_APP_WHL_PERSONA}`}
                onClick={() =>
                  logFrClickEvent({
                    name: "whl",
                    persona: "watch-web",
                  })
                }
              >
                {translation.t("whl")}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={Link}
                to={`/${process.env.REACT_APP_OHL_PERSONA}`}
                onClick={() =>
                  logFrClickEvent({
                    name: "ohl",
                    persona: "watch-web",
                  })
                }
              >
                {translation.t("ohl")}
              </NavDropdown.Item>

              <NavDropdown.Item
                as={Link}
                to={`/${process.env.REACT_APP_QMJHL_PERSONA}`}
                onClick={() =>
                  logFrClickEvent({
                    name: "qmjhl",
                    persona: "watch-web",
                  })
                }
              >
                {translation.t("qmjhl")}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className="me-4"
              href={authentication.createUrl()}
              onClick={() =>
                logFrClickEvent({
                  name: "subscribe-now",
                  persona: "watch-web",
                })
              }
            >
              <i className="bi bi-credit-card-fill me-2" />
              {translation.t("subscribe")}
            </Nav.Link>

            <Nav.Link
              className="me-4"
              as={Link}
              to="/search"
              onClick={() =>
                logFrClickEvent({
                  name: "search",
                  persona: "watch-web",
                })
              }
            >
              <i className="bi bi-search me-2" />
              {translation.t("search")}
            </Nav.Link>

            <Nav.Link
              className="me-3"
              as={Link}
              to="/settings"
              onClick={() =>
                logFrClickEvent({
                  name: "settings",
                  persona: "watch-web",
                })
              }
            >
              <i className="bi bi-gear-fill me-2" />
              {translation.t("settings")}
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <WacNav />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
