import React from "react";
import ReactPlayer from "react-player";
import { ErrorBoundary } from "react-error-boundary";

import { logFrMediaDurationVideoEvent } from "utilities/firebase";
import useTranslation from "hooks/useTranslation";

import "./Player.css";
import audiotrack from "./audiotrack.jpeg";

type SwitchProps = {
  icon: string;
  label: string;
  names: [string, string];
  onSwitch: (state: boolean) => void;
};

const Switch = (props: SwitchProps) => {
  const [state, setState] = React.useState(false);

  const handleClick = () => {
    setState(!state);
    props.onSwitch(!state);
  };

  return (
    <div className="Switch">
      <p className="Switch-Label">{props.label}</p>
      <button className="Switch-Button" onClick={handleClick}>
        {/*<img className="Switch-Image" src={props.icon} alt="" />*/}
        <span>{state ? props.names[1] : props.names[0]}</span>
      </button>
    </div>
  );
};

type PlayerProps = {
  title: string;
  url: string;
  analyticsId: string;
  analyticsTitle: string;
  analyticsLabel1: string;
  analyticsLabel2: string;
  analyticsMediaSource: string;
  analyticsStreamType: string;
};

const Player = (props: PlayerProps) => {
  const ref = React.useRef(null);
  const [playing, setPlaying] = React.useState(false);
  const [showTracks, setShowTracks] = React.useState(false);
  const translation = useTranslation();

  const handlePlay = () => {
    setPlaying(true);

    if (hasTracks()) {
      setShowTracks(true);
    }
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleProgress = (event: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    const unitOfMeasurement = 10;
    const playedSeconds = Math.round(event.playedSeconds);
    const isUnitOfMeasurement = !Boolean(playedSeconds % unitOfMeasurement);

    if (playing && playedSeconds > 0 && isUnitOfMeasurement) {
      logFrMediaDurationVideoEvent({
        seconds_consumed: "10",
        title: props.analyticsTitle,
        persona: "watch-web",
        id: props.analyticsId,
        analytics_label1: props.analyticsLabel1,
        analytics_label2: props.analyticsLabel2,
        media_source: props.analyticsMediaSource,
        stream_type: props.analyticsStreamType,
      });
    }
  };

  const hasTracks = () => {
    if (ref.current) {
      try {
        return (
          (ref.current as any)?.getInternalPlayer("hls")?.audioTracks.length > 1 // undefined > 1 === false
        );
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  const getAudioTrackNames = (): [string, string] => {
    if (ref.current && hasTracks()) {
      return [
        (ref.current as any).getInternalPlayer("hls")?.audioTracks[0].name ??
          "English",
        (ref.current as any).getInternalPlayer("hls")?.audioTracks[1].name ??
          "Stereo",
      ];
    }
    return ["English", "Stereo"];
  };

  const handleSwitch = (state: boolean) => {
    if (ref.current) {
      const hls = (ref.current as any).getInternalPlayer("hls");
      if (hls) {
        hls.audioTrack = state ? 1 : 0;
      }
    }
  };

  return (
    <div>
      <ErrorBoundary
        fallback={<div>Please refresh to reload video.</div>}
        onError={() => console.log("error")}
      >
        <ReactPlayer
          className="Player-Player"
          ref={ref}
          width="100%"
          height="100%"
          url={props.url}
          controls
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
        />
        {showTracks && (
          <Switch
            icon={audiotrack}
            label={translation.t("audioTrack")}
            names={getAudioTrackNames()}
            onSwitch={handleSwitch}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Player;
