const US_INTL: any = {
  locale: "en-US",
  options: {
    date: {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    time: {
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
    },
    datetime: {
      weekday: "long",
      month: "long",
      day: "numeric",
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
    },
  },
};

const FR_INTL: any = {
  locale: "fr-CA",
  options: {
    date: {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    time: {
      hour12: false,
      hour: "numeric",
      minute: "2-digit",
    },
    datetime: {
      weekday: "long",
      month: "long",
      day: "numeric",
      hour12: false,
      hour: "numeric",
      minute: "2-digit",
    },
  },
};

const toDatetime = (dateString: string, lang: string) => {
  const intl = lang === "en" ? US_INTL : FR_INTL;
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime())
    ? date.toLocaleString(intl.locale, intl.options.datetime)
    : dateString;
};

export default toDatetime;
