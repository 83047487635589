import React from "react";
import * as types from "alliance-js-types";

import useCss from "hooks/useCss";
import useTranslation from "hooks/useTranslation";
import Player from "components/Player";
import WacNav from "components/WacNav";

import Chromecast from "components/Chromecast";

import "./MediumDetail.css";

const MediumDetail = (props: types.MediumDetail["props"]) => {
  useCss("url-background", `url(${props.image.src})`, null);
  const tranlation = useTranslation();

  return (
    <div className="MediumDetail">
      <h1 className="MediumDetail-Header">{props.title}</h1>
      <div className="MediumDetail-Body">
        {props.action === "login" ? (
          <WacNav />
        ) : props.action === "subscribe" ? (
          <div>{tranlation.t("subscriptionRequired")}</div>
        ) : (
          <div className="MediumDetail-Player">
            <Chromecast url={props.url}>
              <Player {...props} />
            </Chromecast>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediumDetail;
