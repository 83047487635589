import React from "react";
import * as types from "alliance-js-types";

import ContentCardSlider from "components/ContentCardSlider";
import ContentCard from "components/ContentCard";

import "./MarketingSliderContent16x9.css";

const MarketingSliderContent16x9 = (props: types.MarketingSlider["props"]) => (
  <div className="MarketingSliderContent16x9">
    <ContentCardSlider
      header={""}
      contents={props.tiles}
      content={(content, idx) => (
        <ContentCard
          key={idx}
          type="video"
          link={content.url}
          image={{ src: content.image_url, alt: content.title }}
          text={content.title}
          analyticsId={content.analytics_id}
          analyticsTitle={content.analytics_title}
          colors={{
            background: content.colors?.background!,
            text: content.colors?.text!,
            border: content.colors?.border!,
            footerText: "",
            buttonBackground: "",
            buttonText: "",
          }}
        />
      )}
    />
  </div>
);

export default MarketingSliderContent16x9;
