import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import Navbar from "components/Navbar";
import useConfiguration from "hooks/useConfiguration";
import { pageView } from "utilities/facebook";
import Default from "pages/Default";

import "./Layout.css";

const Layout: React.FC = (props) => {
  const configuration = useConfiguration();
  const location = useLocation();

  // Facebook page view event
  React.useEffect(() => {
    if (configuration) {
      pageView();
    }
  }, [configuration, location]);

  // Redirect to index if no config, when route is invalid
  if (!configuration) {
    return <Default />;
  }

  return (
    <div className="Layout" style={configuration?.css}>
      <header className="Layout-Header">
        <Navbar />
      </header>
      <main className="Layout-Main">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
