import React from "react";
import * as types from "alliance-js-types";

import ContentCardSlider from "components/ContentCardSlider";
import ContentCard from "components/ContentCard";

import "./MarketingSliderCardContent16x9.css";

const MarketingSliderCardContent16x9: React.FC<
  types.MarketingSliderCard["props"]
> = (props) => (
  <div className="MarketingSliderCardContent16x9">
    <ContentCardSlider
      header={`${props.cardHeaderTitle!} ${props.cardHeaderSubtitle}`}
      contents={props.tiles}
      content={(content, idx) => (
        <ContentCard
          key={idx}
          type="video"
          link={content.url}
          image={{ src: content.image_url, alt: content.title }}
          text={content.title}
          analyticsId={content.analytics_id}
          analyticsTitle={content.analytics_title}
          colors={{
            background: content.colors?.background!,
            text: content.colors?.text!,
            border: content.colors?.border!,
            footerText: "",
            buttonBackground: "",
            buttonText: "",
          }}
        />
      )}
    />
  </div>
);

export default MarketingSliderCardContent16x9;
