import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import * as types from "alliance-js-types";

import { toCss } from "utilities/css";
import { logSelectContentEvent } from "utilities/firebase";
import Image from "components/Image";
import useTranslation from "hooks/useTranslation";
import toDatetime from "utilities/toDatetime";
import {
  getVideoId,
  getTeamCode,
  getLeagueCode,
  getLink,
} from "utilities/deeplink";

import "./ContentCard.css";

// Extract teamCode and leagueCode from deeplink.
const createSearchString = (url: string): string => {
  const query: { teamCode?: string; leagueCode?: string } = {};

  const teamCode = getTeamCode(url);
  if (teamCode) {
    query["teamCode"] = teamCode;
  }
  const leagueCode = getLeagueCode(url);
  if (leagueCode) {
    query["leagueCode"] = leagueCode;
  }

  return createSearchParams(query).toString();
};

const DynamicLink = (props: any) => {
  const url = props.link ?? props.button?.link!;
  const videoId = getVideoId(url);

  if (videoId) {
    return (
      <Link
        className="ContentCard"
        style={toCss(props.colors)}
        to={{
          pathname: videoId,
          search: createSearchString(url),
        }}
        onClick={() =>
          logSelectContentEvent({
            id: props.analyticsId,
            title: props.analyticsTitle,
            name: "chl_media_detail",
            persona: "watch-web",
            content_type: "video",
            analytics_label1: props.analyticsLabel1,
            analytics_label2: props.analyticsLabel2,
          })
        }
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <a
        className="ContentCard"
        href={getLink(url)}
        onClick={() =>
          logSelectContentEvent({
            id: props.analyticsId,
            title: props.analyticsTitle,
            name: "chl_media_detail",
            persona: "watch-web",
            content_type: "video",
            analytics_label1: props.analyticsLabel1,
            analytics_label2: props.analyticsLabel2,
          })
        }
      >
        {props.children}
      </a>
    );
  }
};

const ContentCard: React.FC<types.ContentCard> = (props) => {
  const translation = useTranslation();

  return (
    <DynamicLink {...props}>
      <Image
        className="ContentCard-Image"
        src={props.image.src}
        alt={props.image.alt}
        fallback={props.image.fallback}
      />
      <div className="ContentCard-Body">
        <h5 className="ContentCard-Title">{props.text}</h5>
        {props.footer && (
          <p className="ContentCard-Date">
            {toDatetime(props.footer as string, translation.language)}
          </p>
        )}
      </div>
    </DynamicLink>
  );
};

export default ContentCard;
