import React from "react";
import * as types from "alliance-js-types";

import "./ScreenStandard.css";

const ScreenStandard: React.FC<
  types.ScreenStandard["props"] & { children: React.ReactNode }
> = (props) => {
  return <div className="ScreenStandard">{props.children}</div>;
};

export default ScreenStandard;
