import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setUserId,
  setUserProperties,
  logEvent,
} from "firebase/analytics";

/**
 * Analytics configuration.
 * The content of the Firebase config object is considered public.
 * (please see: https://firebase.google.com/docs/projects/learn-more#config-files-objects)
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseUrl: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const setUser = (id: string) => {
  setUserId(analytics, id);
  setUserProperties(analytics, { subscriber_id: id });
};

/**
 * Log select_content analytics event.
 *
 * @param {string} params.title The content title
 * @param {string} params.name The screen name
 * @param {string} params.content_type The content type
 * @param {string} params.persona The persona
 * @param {string} params.analytics_label_1 The analytics label1
 * @param {string} params.analytics_label_2 The analytics label2
 */
export const logSelectContentEvent = (params: {
  id: string;
  title: string;
  name: string;
  content_type: "video";
  persona: "watch-web";
  analytics_label1: string;
  analytics_label2: string;
}): void => {
  logEvent(analytics, "select_content", params);
};

/**
 * Log fr_media_duration_video analytics event.
 *
 * @param {string} params.seconds_consumed The consumed seconds
 * @param {string} params.title The title of the medium
 * @param {string} params.persona The persona
 * @param {string} params.analytics_label1 The analytics label1
 * @param {string} params.analytics_label2 The analytics label2
 */
export const logFrMediaDurationVideoEvent = (params: {
  id: string;
  seconds_consumed: string;
  title: string;
  persona: "watch-web";
  analytics_label1: string;
  analytics_label2: string;
  media_source: string;
  stream_type: string;
}): void => {
  logEvent(analytics, "fr_media_duration_video", params);
};

/**
 * Log fr_click_event analytics event.
 *
 * @param {string} params.name The target name
 * @param {string} params.persona The persona
 */
export const logFrClickEvent = (params: {
  name: string;
  persona: "watch-web";
}): void => {
  logEvent(analytics, "fr_click", params);
};

/**
 * Log search analytics event.
 *
 * @param {string} params.search_term The search term
 * @param {string} params.persona The persona
 */
export const logSearchEvent = (params: {
  search_term: string;
  persona: "watch-web";
}): void => {
  logEvent(analytics, "search", params);
};
