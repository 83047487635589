import React from "react";

import translations from "./translations";

import "./TranslationProvider.css";

type TranslationContextType = {
  t: (key: string) => string;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
};

export const TranslationContext = React.createContext<TranslationContextType>(
  null!
);

const TranslationProvider = (props: any) => {
  const [language, setLanguage] = React.useState<string>(
    localStorage.getItem("language") ?? "en"
  );

  // Translate by key
  const t = (key: string) => {
    if (!translations[language][key]) {
      console.warn(
        `Translation "${key}" for language "${language}" not found.`
      );
    }

    return translations[language][key] ?? "";
  };

  // Save language to local storage
  React.useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <TranslationContext.Provider value={{ t, language, setLanguage }}>
      {props.children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
