import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./index.css";

Sentry.init({
  dsn: "https://2c6155a8e25947f294d47fc551d80c98@o471870.ingest.sentry.io/6726803",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.001,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <App />
  </Sentry.ErrorBoundary>
);

//root.render(
//  <React.StrictMode>
//    <Sentry.ErrorBoundary fallback={<p>An error has accured</p>}>
//      <App />
//    </Sentry.ErrorBoundary>
//  </React.StrictMode>
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
