import React from "react";
import * as types from "alliance-js-types";

import ContentCard from "components/ContentCard";

import "./ContentCardGrid.css";

const ContentCardGrid = (props: types.ContentGrid["props"]) => (
  <div className="ContentCardGrid">
    {props.contents.map((content, idx) => (
      <ContentCard key={idx} {...content} />
    ))}
  </div>
);

export default ContentCardGrid;
