import React from "react";

import "./Image.css";

type ImageProps = {
  className: string;
  src: string;
  alt: string;
  fallback?: string;
};

const Image = (props: ImageProps) => {
  const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    (e.target as HTMLImageElement).style.opacity = "1";
    (e.target as HTMLImageElement).style.transition = "opacity 0.5s ease-in";
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    (e.target as HTMLImageElement).style.display = "none";
  };

  return (
    <div
      className={props.className}
      style={{
        background: `url(${props.fallback}) no-repeat center center / cover`,
        overflow: "hidden",
      }}
    >
      <img
        src={props.src ?? ""}
        alt={props.alt}
        width="100%"
        height="100%"
        loading="lazy"
        onLoad={handleLoad}
        onError={handleError}
        style={{ opacity: 0 }}
      />
    </div>
  );
};

export default Image;
