import React from "react";

import { Link } from "react-router-dom";

import useTranslation from "hooks/useTranslation";
import { logFrClickEvent } from "utilities/firebase";

import "./LeagueNav.css";

type LeagueNavProps = {};

const LeagueNav = (props: LeagueNavProps) => {
  const translation = useTranslation();

  return (
    <div className="LeagueNav">
      <h1 className="LeagueNav-Header">{translation.t("selectLeague")}</h1>
      <div className="LeagueNav-Links">
        <Link
          className="LeagueNav-Link"
          to={`/${process.env.REACT_APP_WHL_PERSONA}`}
          onClick={() =>
            logFrClickEvent({
              name: "whl",
              persona: "watch-web",
            })
          }
        >
          <img
            className="LeagueNav-Image"
            src="https://dzjigeagixv4i.cloudfront.net/WHL.jpg"
            alt="whl"
          />
        </Link>

        <Link
          className="LeagueNav-Link"
          to={`/${process.env.REACT_APP_OHL_PERSONA}`}
          onClick={() =>
            logFrClickEvent({
              name: "ohl",
              persona: "watch-web",
            })
          }
        >
          <img
            className="LeagueNav-Image"
            src="https://dzjigeagixv4i.cloudfront.net/OHL.jpg"
            alt="ohl"
          />
        </Link>

        <Link
          className="LeagueNav-Link"
          to={`/${process.env.REACT_APP_QMJHL_PERSONA}`}
          onClick={() =>
            logFrClickEvent({
              name: "qmjhl",
              persona: "watch-web",
            })
          }
        >
          <img
            className="LeagueNav-Image"
            src="https://dzjigeagixv4i.cloudfront.net/LHJMQ.jpg"
            alt="qmjhl"
          />
        </Link>
      </div>
    </div>
  );
};

export default LeagueNav;
