import { useQuery } from "react-query";
import axios from "axios";
import * as types from "alliance-js-types";

/**
 * Layout params.
 */
type LayoutParams = {
  app: string;
  persona: string;
  screen: string;
  release: string;
  query?: Record<string, string>;
};

/**
 * Build layout url.
 */
const buildURL = (params: LayoutParams): string => {
  const url = new URL(
    `${process.env.REACT_APP_AJSB_URL}/${params.app}/${params.persona}/${params.screen}`
  );

  url.searchParams.append("release_id", params.release);
  if (params.query) {
    Object.entries(params.query).forEach((param) => {
      url.searchParams.append(param[0], param[1]);
    });
  }

  return url.toString();
};

/**
 * Get layout data.
 */
const getLayout = async (params: LayoutParams): Promise<types.Layout> => {
  return (await axios.get(buildURL(params))).data;
};

/**
 * Use layout data (hook).
 */
const useLayout = (params: LayoutParams) => {
  return useQuery({
    queryKey: ["layout", params],
    queryFn: () => getLayout(params),
  });
};

export default useLayout;
