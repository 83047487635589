import React from "react";

import SearchBar from "components/SearchBar";
import useConfiguration from "hooks/useConfiguration";
import Cards from "components/Cards";

import "./Search.css";

const Search = () => {
  const configuration = useConfiguration();
  const [search, setSearch] = React.useState<string>("");

  return (
    <div className="Search">
      <SearchBar onSearch={(value) => setSearch(value)} />
      {search && (
        <Cards
          app={configuration.appLookupKey}
          persona={configuration.personaLookupKey}
          screen={"chl_search"}
          release={configuration.releaseId}
          query={{ search: search }}
        />
      )}
    </div>
  );
};

export default Search;
