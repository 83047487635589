import React from "react";

import components from "components";

// Type inspired by React.ReactElement
export type Element = {
  type: keyof typeof components;
  props: {
    [key: string]: any;
  };
};

/**
 * Render a React element tree from traversin Layout element tree.
 *
 * @param {Element} element - A Layout element.
 * @param {number} key - A stack depth key.
 * @return {React.ReactElement} A React element.
 * @throws {TypeError} Invalid element.
 *
 * @example
 *
 *	React.render(create(element), document.getElementById("root"));
 *
 */

export const render = (
  element: Element,
  key: number = 0
): React.ReactElement => {
  const Type: any = components[element.type];
  const Props = { ...element.props, key };
  const Children = element.props.children?.map(
    (child: Element, idx: number) => {
      const isElement = Object.keys(components).includes(child?.type as string);

      return isElement ? render(child, idx) : null;
    }
  );

  return React.createElement(Type, Props, Children);
};
