import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { useMediaQuery } from "react-responsive";

import "./ContentCardSlider.css";

const group = (array: any[], size: number) => {
  const out = [];
  for (var i = 0; i < array.length; i += size) {
    const slice = array.slice(i, i + size);
    out.push(slice);
  }
  return out;
};

type ContentCardSliderProps = {
  header: string;
  contents: any;
  content: (content: any, idx: number) => any;
};

const ContentCardSlider = (props: ContentCardSliderProps) => {
  const columns = useMediaQuery({ query: "(max-width: 1024px)" }) ? 2 : 5; // 2 columns for mobile and 5 for desktop

  return (
    <div className="ContentCardSlider">
      <h2 className="ContentCardSlider-Header">{props.header}</h2>
      <div className="ContentCardSlider-Body">
        <Carousel interval={null} indicators={false} slide={true}>
          {group(props.contents, columns).map((group, idx) => (
            <Carousel.Item key={idx}>
              <div className="ContentCardSlider-Group">
                {group.map(props.content)}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ContentCardSlider;
