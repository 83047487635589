import React from "react";

import useConfiguration from "hooks/useConfiguration";
import useTranslation from "hooks/useTranslation";
import Cards from "components/Cards";
import LeagueNav from "components/LeagueNav";

import "./Home.css";

const Home = () => {
  const configuration = useConfiguration();
  const translation = useTranslation();

  // TODO convert LeagueNav to ajsb card

  return (
    <div className="Home">
      {configuration.personaLookupKey.includes("chl_persona") && <LeagueNav />}
      <Cards
        app={configuration.appLookupKey}
        persona={configuration.personaLookupKey}
        screen={"watch_home_overview"}
        release={configuration.releaseId}
        query={{ language_code: translation.language }}
      />
    </div>
  );
};

export default Home;
