import React from "react";

/**
 * Set CSS proprty at :root on component mount and unset on component unmount.
 */
const useCss = (key: string, mount: string, unmount: string | null) => {
  React.useEffect(() => {
    document.documentElement.style.setProperty(`--${key}`, mount);
    return () => {
      document.documentElement.style.setProperty(`--${key}`, unmount);
    };
  }, [key, mount, unmount]);
};

export default useCss;
