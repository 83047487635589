import ReactPixel from "react-facebook-pixel";

/**
 * Analytics configuration.
 */

ReactPixel.init("226317348058447");

/**
 * Log page_view analytics event.
 *
 * @param {string} title The content title
 * @param {string} name The screen name
 * @param {string} content_type The content type
 * @param {string} persona The persona
 */
export const pageView = (): void => {
  ReactPixel.pageView();
};
