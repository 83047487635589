import React from "react";
import { useQueries } from "react-query";

import { Configuration, fetchConfiguration } from "utilities/configuration";
import Loader from "components/Loader";

import "./ConfigurationProvider.css";

// Persona lookup keys used to prefetch admin static configs
const PERSONA_LOOKUP_KEYS = [
  process.env.REACT_APP_CHL_PERSONA!,
  process.env.REACT_APP_OHL_PERSONA!,
  process.env.REACT_APP_WHL_PERSONA!,
  process.env.REACT_APP_QMJHL_PERSONA!,
];

type ConfigurationContextType = Record<string, Configuration>;

export const ConfigurationContext =
  React.createContext<ConfigurationContextType>(null!);

const useConfigurationQuery = () => {
  // Prefetch all static configs
  const queries = useQueries(
    PERSONA_LOOKUP_KEYS.map((personaLookupKey, i) => ({
      queryKey: ["configuration", personaLookupKey],
      queryFn: () => fetchConfiguration(personaLookupKey),
    }))
  );

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);
  const error = queries.find((query) => query.isError);
  const data = queries.reduce((previous, current, index) => {
    previous[PERSONA_LOOKUP_KEYS[index]] = current.data;
    return previous;
  }, {} as any);

  return { isLoading, isError, error, data };
};

/**
 * Provide admin static configs to app.
 */
const ConfigurationProvider = (props: any) => {
  const configuration = useConfigurationQuery();

  return (
    <ConfigurationContext.Provider value={configuration.data}>
      {configuration.isLoading ? (
        <div className="ConfigurationProvider-Loading">
          <Loader />
        </div>
      ) : configuration.isError ? (
        <div className="ConfigurationProvider-Error">
          Configuration Error:
          {(configuration.error as unknown as Error).message}
        </div>
      ) : (
        props.children
      )}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
