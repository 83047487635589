/**
 * Configuration dto.
 */
type ConfigurationDto = {
  id: number;
  lookup_key: string;
  app_id: number;
  app_name: string;
  release_id: number;
  language: string;
  display_name: string;
  logo_url: string;
  logo_background_color: string;
  time_zone: string;
  league_code: string;
  team_code: string;
  media_league_code: string;
  media_team_code: string;
  media_available_languages: string;
  colors: { lookup_key: string; name: string; value: string }[];
};

/**
 * Configuration domain entity.
 */
export class Configuration {
  readonly data: ConfigurationDto;

  get appLookupKey(): string {
    return "chl"; // FIXME map personaLookupKey to appLookupKey just for AJSB
    //return this.data.league_code;
  }

  get personaLookupKey(): string {
    return this.data.lookup_key;
  }

  get releaseId(): string {
    return this.data.release_id.toString();
  }

  get logoUrl(): string {
    // TODO this is horrible! move this to admin.
    if (this.personaLookupKey.includes("chl_persona")) {
      return "https://s3.amazonaws.com/alliance-js-assets.fanreachdata.io/chl_persona/chltv.png";
    }

    return this.data.logo_url;
  }

  get colors(): Record<string, string> {
    return this.data.colors.reduce((acc, color) => {
      (acc as any)[color.lookup_key] = color.value;
      return acc;
    }, {} as any);
  }

  get css() {
    return Object.keys(this.colors).reduce((acc, key) => {
      const newKey = "--color-" + key;
      acc[newKey] = this.colors[key];
      return acc;
    }, {} as Record<string, string>);
  }

  constructor(data: ConfigurationDto) {
    this.data = data;
  }
}

/**
 * Get configuration data.
 *
 * @return {Configuration} Configuration domain object.
 * @param personaLookupKey
 */
export const fetchConfiguration = async (
  personaLookupKey: string
): Promise<Configuration> => {
  const data: ConfigurationDto = await import(
    `../configs/persona/${personaLookupKey.replaceAll("_", "-")}-ios-en.json`
  );

  return new Configuration(data);
};
