import MarketingSliderCardContent16x9 from "./MarketingSliderCardContent16x9";
import MarketingSliderContent16x9 from "./MarketingSliderContent16x9";
import ContentSliderCardHorizontalComfortable16x9 from "./ContentSliderCardHorizontalComfortable16x9";
import ScreenStandard from "./ScreenStandard";
import ContentCardGrid from "./ContentCardGrid";
import MediumDetail from "./MediumDetail";

const components: any = {
  MarketingSliderCardContent16x9,
  MarketingSliderContent16x9,
  ContentSliderCardHorizontalComfortable16x9,
  ScreenStandard,
  ContentCardGrid,
  MediumDetail,
};

export default components;
