import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import ConfigurationProvider from "components/ConfigurationProvider";
import TranslationProvider from "components/TranslationProvider";
import AuthenticationProvider from "components/AuthenticationProvider";
import Layout from "components/Layout";
import Default from "pages/Default";
import Home from "pages/Home";
import Detail from "pages/Detail";
import Schedule from "pages/Schedule";
import Settings from "pages/Settings";
import Search from "pages/Search";
//import { ReactQueryDevtools } from "react-query/devtools";
//<ReactQueryDevtools initialIsOpen={false} />

import "./App.css";

const App: React.FC = () => (
  <div className="App">
    <BrowserRouter>
      <QueryClientProvider client={new QueryClient()}>
        <ConfigurationProvider>
          <TranslationProvider>
            <AuthenticationProvider>
              <Routes>
                <Route path="/" element={<Default />} />
                <Route element={<Layout />}>
                  <Route path=":personaLookupKey" element={<Home />} />
                  <Route
                    path=":personaLookupKey/:detailId"
                    element={<Detail />}
                  />
                  <Route path="schedule" element={<Schedule />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="search" element={<Search />} />
                  <Route path="search/:detailId" element={<Detail />} />
                </Route>
              </Routes>
            </AuthenticationProvider>
          </TranslationProvider>
        </ConfigurationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </div>
);

export default App;
