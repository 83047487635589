import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import decode from "jwt-decode";
import { setUser } from "utilities/firebase";
import useTranslation from "hooks/useTranslation";

import axios from "axios";

import "./AuthenticationProvider.css";

type AuthenticationContextType = {
  token: string | null;
  signin: () => void;
  signout: () => void;
  createUrl: (path?: string) => string;
};

export const AuthenticationContext =
  React.createContext<AuthenticationContextType>(null!);

const AuthenticationProvider: React.FC<any> = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [rscode, setRscode] = React.useState<string | undefined>();
  const [token, setToken] = React.useState<string | null>(
    localStorage.getItem("token")!
  );

  /**
   *  Create url
   */
  const createUrl = (path = "/"): string => {
    const url = new URL(path, process.env.REACT_APP_WAC_URL!);
    url.searchParams.append("return_url", window.location.href); // add current location
    url.searchParams.append("language", translation.language); // add current location
    if (rscode) {
      url.searchParams.append("rscode", rscode); // add rscode if exists
    }
    return url.toString();
  };

  /**
   *  Signin
   *  Delegate authentication to WAC by adding the current location to the
   *  query string of the url and redirecting.
   */
  const signin = () => {
    const url = new URL(process.env.REACT_APP_WAC_URL!);
    url.searchParams.append("return_url", window.location.href); // add current location
    url.searchParams.append("language", translation.language); // add current location
    if (rscode) {
      url.searchParams.append("rscode", rscode); // add rscode if exists
    }
    window.location.replace(url); // redirect
  };

  /**
   * Signin callback
   * If token in query string then extract it and add it to storage.
   */
  React.useEffect(() => {
    // Get token param from url
    const token = searchParams.get("token");

    if (token) {
      // Save token to session storage
      localStorage.setItem("token", token);

      // Save cleengId to Google Analytics
      setUser((decode(token) as any).cleengId);

      // Update state
      setToken(token);

      // Delete token from url
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  /**
   * On page load callback
   * If rscode in query string then extract it and add it to storage.
   */
  React.useEffect(() => {
    // Get rscode from url
    const rscode = searchParams.get("rscode");

    if (rscode) {
      // Update state
      setRscode(rscode);

      // Delete rscode from url
      searchParams.delete("rscode");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  /**
   * Signout
   * Remove token from storage.
   */
  const signout = () => {
    // Remove token
    localStorage.removeItem("token");
    setToken(null);

    // WAC signout
    axios.get(process.env.REACT_APP_WAC_URL!, { params: { logout: true } });

    // Redirect to index
    navigate("/");
  };

  return (
    <AuthenticationContext.Provider
      value={{ token, signin, signout, createUrl }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
