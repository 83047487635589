/**
 * Set a CSS property to :root.
 */
export const setCssProperty = (key: string, value: string) => {
  document.documentElement.style.setProperty(key, value);
};

/**
 * Convert object keys to CSS property notation.
 */
export const toCss = (object: Record<string, string>, prefix = "color") => {
  return Object.keys(object ?? {}).reduce((acc, key) => {
    const newKey = "--" + prefix + "-" + key;
    acc[newKey] = object[key];
    return acc;
  }, {} as Record<string, string>);
};
