import React from "react";
import { Navigate } from "react-router-dom";

import "./Default.css";

const Default = () => {
  return <Navigate replace to={`/${process.env.REACT_APP_CHL_PERSONA}`} />;
};

export default Default;
