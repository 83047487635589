import React from "react";
import * as types from "alliance-js-types";

import ContentCardSlider from "components/ContentCardSlider";
import ContentCard from "components/ContentCard";

import "./ContentSliderCardHorizontalComfortable16x9.css";

const ContentSliderCardHorizontalComfortable16x9 = (
  props: types.ContentSlider["props"]
) => (
  <div className="ContentSliderCardHorizontalComfortable16x9">
    <ContentCardSlider
      header={`${props.cardHeaderTitle!} ${props.cardHeaderSubtitle}`}
      contents={props.contents}
      content={(content, idx) => <ContentCard key={idx} {...content} />}
    />
  </div>
);

export default ContentSliderCardHorizontalComfortable16x9;
