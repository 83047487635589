import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import useConfiguration from "hooks/useConfiguration";
import useAuthentication from "hooks/useAuthentication";
import Cards from "components/Cards";

import "./Detail.css";

const Detail = () => {
  const configuration = useConfiguration();
  const token = useAuthentication().token;
  const detailId = useParams().detailId!;
  const [searchParams] = useSearchParams();

  // Initialize query
  const query: {
    detailId: string;
    teamCode?: string;
    leagueCode?: string;
    token?: string;
  } = { detailId, ...Object.fromEntries(searchParams) };

  // If user is logged in append token to query
  if (token) {
    query["token"] = token;
  }

  return (
    <div className="Detail">
      <Cards
        app={configuration.appLookupKey}
        persona={configuration.personaLookupKey}
        release={configuration.releaseId}
        screen="chl_medium_detail"
        query={query}
      />
    </div>
  );
};

export default Detail;
