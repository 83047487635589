import React from "react";
import { useParams } from "react-router-dom";

import { ConfigurationContext } from "components/ConfigurationProvider";

const useConfiguration = () => {
  const data = React.useContext(ConfigurationContext);
  const personaLookupKey =
    useParams().personaLookupKey ?? process.env.REACT_APP_CHL_PERSONA!; // TODO default persona

  return data[personaLookupKey];
};

export default useConfiguration;
