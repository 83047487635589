import React from "react";

import "./Settings.css";

import useTranslation from "hooks/useTranslation";

const Settings = () => {
  const translation = useTranslation();

  return (
    <div className="Settings">
      <article className="Settings-Article">
        <h1 className="Settings-Heading">{translation.t("language")}</h1>
        <div className="Settings-Setting">
          {translation.t("languageText")}
          <button
            className="Settings-Language"
            onClick={() =>
              translation.setLanguage(
                translation.language === "en" ? "fr" : "en"
              )
            }
          >
            {translation.language}
          </button>
        </div>
      </article>
      <article className="Settings-Article">
        <h1 className="Settings-Heading">{translation.t("support")}</h1>
        <p className="Settings-Paragraph">{translation.t("supportText")}</p>
        <div className="Settings-Links">
          <a
            className="Settings-Link"
            href="https://chlsupport.zendesk.com/hc/en-us"
          >
            https://watch.chl.ca/support
          </a>
        </div>
      </article>
      <article className="Settings-Article">
        <h1 className="Settings-Heading">{translation.t("about")}</h1>
        <p className="Settings-Paragraph">{translation.t("aboutText")}</p>
        <div className="Settings-Links">
          <br />
          <a className="Settings-Link" href="https://chl.ca/privacy_policy">
            {translation.t("pp")}
          </a>
          <br />
          <a
            className="Settings-Link"
            href="https://chlsupport.zendesk.com/hc/en-us"
          >
            {translation.t("tc")}
          </a>
        </div>
      </article>
    </div>
  );
};

export default Settings;
