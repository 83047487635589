/**
 * Get video id from deeplink
 */
export const getVideoId = (deeplink: string): string | undefined => {
  try {
    const url = new URL(deeplink);
    return url.searchParams.get("video_id") ?? undefined;
  } catch (error) {
    return undefined;
  }
};

/**
 * Get perosnal lookup key from deeplink
 */
export const getPersonaLookupKey = (deeplink: string): string | undefined => {
  try {
    const url = new URL(deeplink);
    return url.searchParams.get("persona_lookup_key") ?? undefined;
  } catch (error) {
    return undefined;
  }
};

/**
 * Get team code from deeplink
 */
export const getTeamCode = (deeplink: string): string | undefined => {
  try {
    const url = new URL(deeplink);
    return url.searchParams.get("team_code") ?? undefined;
  } catch (error) {
    return undefined;
  }
};

/**
 * Get league code from deeplink
 */
export const getLeagueCode = (deeplink: string): string | undefined => {
  try {
    const url = new URL(deeplink);
    return url.searchParams.get("league_code") ?? undefined;
  } catch (error) {
    return undefined;
  }
};

/**
 * Get link from deeplink
 */
export const getLink = (deeplink: string): string | undefined => {
  try {
    const url = new URL(deeplink);
    if (["http", "https"].some((protocol) => url.protocol.includes(protocol))) {
      return url.toString();
    } else {
      return url.searchParams.get("link") ?? undefined;
    }
  } catch (error) {
    return undefined;
  }
};
